/* import not thunk method */
import Post from "./Post";
import Get from "./Get";
import Delete from "./Delete";
import Put from "./Put";

/* import service api for thunk method */
import GetThunk from "./GetThunks";

/* service get */
const getChattAgent = () => Get("chatting");
const validateSession = (config) => Get("api/validate", false, "v1", config);
const getListChatClient = () => Get("chatting");
const getDetailChatClient = (path, config) =>
  GetThunk(path, true, "socket", config);
const getDetailClientChat = (path, config) =>
  GetThunk(path, true, "socket", config);

/* service post */
const authUserToSocket = (data) => Post("login", true, data, "socket");
const uploadFileChat = (path, data, config) =>
  Post(path, false, data, "v1", config);

/* declare routing */
const Service = {
  getChattAgent,
  validateSession,
  authUserToSocket,
  getListChatClient,
  getDetailChatClient,
  getDetailClientChat,
  uploadFileChat,
};

export default Service;
