/* component */
import {
  Button,
  ButtonDropdown,
  Col,
  DropdownToggle,
  Form,
  Input,
  Label,
  Row,
  UncontrolledTooltip,
} from 'reactstrap';
import { useMediaQuery } from 'react-responsive';
import styles from './InputChat.module.css';

/* library & helpers */
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { notify } from '../../utils/helpers';

/* reducer & service*/
import { useSelector } from 'react-redux';
import { layoutSetupSelector } from '../../../app/Layouts/LayoutSlice';
import { detailChatClientSelector } from '../../../features/Client/DetailChat/DetailChatClientSlice';
import { sendMessage } from '../../WebSocket/Clients/ClientActions';
import { useEffect, useRef } from 'react';

function InputChatClient(props) {
  /* configuration */
  const { messageClient, updateMessage, chooseFile, isFileChoosed, setIsFileChoosed } = props;
  const initialValues = { message: '' };
  const schemas = Yup.object({
    message: Yup.string()
      .required('Message is required!')
      .min(1, 'Insert message min 1 character!'),
  });
  const refMessage = useRef(null);

  /* config display */
  const isDisplay992 = useMediaQuery({ query: '(max-width:992px)' });
  const isDisplay991 = useMediaQuery({ query: '(max-width:991px)' });
  const isDisplay768 = useMediaQuery({ query: '(max-width:768px)' });

  /* state */
  const { chatId } = useSelector(detailChatClientSelector);
  const { layoutMode } = useSelector(layoutSetupSelector);

  // const clearChooseFile = () => {
  //   document.getElementById('inputAllType').value = null;
  //   document.getElementById('inputFileImage').value = null;
  // };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: schemas,
    onSubmit: (values, actions) => {
      const message = values.message
        .replace(/&nbsp;/g, '')
        .replace(/<br>$/g, '\n')
        .trim();

      sendMessage(chatId, message);
      actions.resetForm();
    },
  });

  const handlerKeyupMessage = (event) => {
    if (event.which === 13 && !event.shiftKey) {
      const message = event.target.innerText
        .replace(/<\/div>/gi, '')
        .replace(/^(<br>)+|(<br>)+$/g, '')
        .replace(/\s\s+/g, '')
        .replace(/&nbsp;/g, '');
      if (message.length < 1) {
        notify('error', 3000, 'Please type message or send file!');
        updateMessage('');
      } else {
        formik.handleSubmit();
      }

      event.preventDefault();
    }
  };

  const handlerOnInputMessage = (event) => {
    const element = event.target;
    const message = element.innerText;

    formik.setFieldValue('message', message);
    updateMessage(message);

    const clientHeight = event.target.clientHeight;
    let setupHeightChatConversation = 0;
    let transition = '';
    if (Boolean(isDisplay992)) {
      if (clientHeight > 100) {
        setupHeightChatConversation = '100vh - 290px';
      } else if (clientHeight > 79) {
        setupHeightChatConversation = '100vh - 270px';
      } else if (clientHeight > 58) {
        setupHeightChatConversation = '100vh - 250px';
      } else if (clientHeight > 41) {
        setupHeightChatConversation = '100vh - 230px';
      } else {
        setupHeightChatConversation = '100vh - 210px';
      }
    } else if (Boolean(isDisplay991)) {
      console.log('991 is true!');
    } else if (Boolean(isDisplay768)) {
      console.log('768 is true!');
    } else {
      if (clientHeight > 100) {
        setupHeightChatConversation = '100vh - 270px';
      } else if (clientHeight > 79) {
        setupHeightChatConversation = '100vh - 255px';
      } else if (clientHeight > 58) {
        setupHeightChatConversation = '100vh - 230px';
      } else if (clientHeight > 41) {
        setupHeightChatConversation = '100vh - 200px';
      } else {
        setupHeightChatConversation = '100vh - 185px';
      }
    }

    document
      .querySelector('.chat-conversation')
      .setAttribute(
        'style',
        `height: calc(${setupHeightChatConversation});${transition}`
      );
  };

  /* onload */
  useEffect(() => {
    refMessage.current.focus();
  }, [refMessage]);

  useEffect(() => {
    if (isFileChoosed) {
      document.getElementById('inputAllType').value = null;
      document.getElementById('inputFileImage').value = null;
      setIsFileChoosed(false);
    }
  }, [isFileChoosed]);

  return (
    <>
      <div className="p-3 p-lg-4 border-top mb-0">
        <Form onSubmit={formik.handleSubmit}>
          <Row className="g-0">
            <Col>
              <div>
                <div
                  ref={refMessage}
                  name="message"
                  id="message"
                  placeholder="Enter Message..."
                  contentEditable={true}
                  value={!Boolean(messageClient) ? '' : messageClient}
                  onKeyDown={handlerKeyupMessage}
                  onInput={handlerOnInputMessage}
                  className={`form-control form-control-lg bg-light border-light ${layoutMode === 'dark'
                    ? styles.formContenteditableDark
                    : styles.formContenteditableLight
                    }`}
                ></div>
              </div>
            </Col>
            <Col className="d-none d-md-block d-lg-block" xs="auto">
              <div className="chat-input-links ms-md-2">
                <ul className="list-inline mb-0 ms-0">
                  <li className="list-inline-item">
                    <ButtonDropdown className="emoji-dropdown" direction="up">
                      <DropdownToggle
                        id="emoji"
                        color="link"
                        className="text-decoration-none font-size-16 btn-lg waves-effect"
                      >
                        <i className="ri-emotion-happy-line"></i>
                      </DropdownToggle>
                    </ButtonDropdown>
                    <UncontrolledTooltip target="emoji" placement="top">
                      Emoji
                    </UncontrolledTooltip>
                  </li>
                  <li className="list-inline-item input-file">
                    <Label
                      id="files"
                      className="btn btn-link text-decoration-none font-size-16 btn-lg waves-effect"
                    >
                      <i className="ri-attachment-line"></i>
                      <Input
                        id="inputAllType"
                        type="file"
                        name="fileInput"
                        size="60"
                        onChange={chooseFile}
                      />
                    </Label>
                    <UncontrolledTooltip target="files" placement="top">
                      Attached File
                    </UncontrolledTooltip>
                  </li>
                  <li className="list-inline-item input-file">
                    <Label
                      id="images"
                      className="me-1 btn btn-link text-decoration-none font-size-16 btn-lg waves-effect"
                    >
                      <i className="ri-image-fill"></i>
                      <Input
                        id="inputFileImage"
                        accept=".gif,.ico,.jpg,.jpeg,.png,.svg,.tif,.tiff,.webp"
                        type="file"
                        name="fileInput"
                        size="60"
                        onChange={chooseFile}
                      />
                    </Label>
                    <UncontrolledTooltip target="images" placement="top">
                      Images
                    </UncontrolledTooltip>
                  </li>
                  <li className="list-inline-item">
                    <Button
                      type="submit"
                      color="tangerin"
                      className="font-size-16 btn-lg chat-send waves-effect waves-light"
                    >
                      <i className="ri-send-plane-2-fill"></i>
                    </Button>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
      {/* <ModalMd
        isOpen={previewFileModal}
        handlerFunc={togglePreviewFileModal}
        title="Preview File"
        headerModal={true}
        unmountOnClose={true}
        keyboard={true}
        centered={true}
        footerModal={<ModalFooter />}
        backdrop="static"
      >
        <div
          className={`d-flex justify-content-center align-items-center ${
            uploadFileResult.fileType === 'image' ? 'image-preview' : ''
          }  `}
        >
          <Row>
            <Col sm="12" md="12" lg="12">
              <PreviewFile resultUpload={uploadFileResult} />
            </Col>
            <Col sm="12" md="12" lg="12">
              <InputOnPreview
                chatId={chatId}
                resultUpload={uploadFileResult}
                togglePreviewFileModal={(val) => togglePreviewFileModal(val)}
              />
            </Col>
          </Row>
        </div>
      </ModalMd> */}
    </>
  );
}

export default InputChatClient;
