import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { joinRoom } from '../../../Common/WebSocket/Clients/ClientActions';
import { getClientDetailChat, getDetailChat } from './DetailChatClientAPI';

const initialState = {
  /* info open: not_open|open|error|loader */
  isOpen: 'not_open',
  errorMessage: null,

  chatId: null,
  listBubbleChat: [],
  detailClient: {},
  listLabel: [],

  /* actionStatus: join|error|null */
  actionStatus: null,

  runNavigateClose: false,
};

/* get chat detail */
export const getChatDetailClient = createAsyncThunk(
  'detailChatCLientSetup/getChatDetail',
  async (chatId) => {
    const response = await getDetailChat(chatId);
    if (response.status === 200) {
      const dataRes = response.data.data;
      if ([0, 2].includes(dataRes.status)) {
        joinRoom(dataRes.chat_id);
      }
    }
    return response;
  }
);

/* get detail client chat */
export const getDetailClientChat = createAsyncThunk(
  'detailChatClientSetup/getDetailClient',
  async (chatId) => {
    const response = await getClientDetailChat(chatId);
    return response;
  }
);

export const DetailChatClientSlice = createSlice({
  name: 'detailChatCLientSetup',
  initialState,
  reducers: {
    updateOpenChat: (state, action) => {
      state.isOpen = 'loader';
      state.chatId = action.payload.chat_id;
    },
    closeChat: (state) => {
      state.isOpen = 'not_open';
      state.chatId = null;
      state.detailClient = {};
      state.listBubbleChat = [];
      state.runNavigateClose = true;
    },
    updateStatusNavigate: (state) => {
      state.runNavigateClose = false;
    },
    addMessage: (state, action) => {
      state.listBubbleChat.push(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getChatDetailClient.pending, (state) => {
        state.isOpen = 'loader';
        state.listBubbleChat = [];
        state.listLabel = [];

        state.actionStatus = null;
        return state;
      })
      .addCase(getChatDetailClient.fulfilled, (state, action) => {
        const resData = action.payload.data.data;
        state.isOpen = 'open';
        state.detailClient = {
          chat_id: resData.chat_id,
          id_channel: resData.id_channel,
          topic_name: resData.topic_name,
          user_name: resData.user_name,
          user_email: resData.user_email,
          status: resData.status,
          channel_name: resData.channel_name,
          department_name: resData.department_name,
        };

        state.chatId = resData.chat_id;

        state.listBubbleChat = resData.chat_reply;

        state.actionStatus = 'join';

        return state;
      })
      .addCase(getChatDetailClient.rejected, (state, action) => {
        state.isOpen = 'error';
        state.chatId = null;
        state.listBubbleChat = [];
        state.detailClient = {};
        state.listLabel = [];

        state.actionStatus = 'error';

        return state;
      })
      .addCase(getDetailClientChat.pending, (state) => {
        state.isOpen = 'loader';
        state.listBubbleChat = [];
        state.listLabel = [];
        state.detailClient = {};
        return state;
      })
      .addCase(getDetailClientChat.fulfilled, (state, action) => {
        const resData = action.payload.data.data;
        state.detailClient = {
          chat_id: resData.chat_id,
          id_channel: resData.id_channel,
          topic_name: resData.topic_name,
          user_name: resData.user_name,
          user_email: resData.user_email,
          user_avatar: resData.user_avatar,
        };

        return state;
      });
  },
});

export const detailChatClientSelector = (state) => state.detailChatCLientSetup;

export const { updateOpenChat, closeChat, addMessage, updateStatusNavigate } =
  DetailChatClientSlice.actions;

export default DetailChatClientSlice.reducer;
