import { Fragment, useState } from 'react';

/* redux and reducer */
import { useSelector } from 'react-redux';
import { layoutSetupSelector } from '../../../../app/Layouts/LayoutSlice';
import { onlineUsersSelector } from './OnlineUsers/OnlineUsersSlice';

/* component and library ui */
import ListChat from './ListChat/ListChat';
import OnlineUsers from './OnlineUsers/OnlineUsers';
import TabCategoryChat from './TabCategoryChat/CategoryChat';
import ClientHeaderTab from '../../../../Common/Components/HeaderTab/ClientHeaderTab';

const ChatWithClients = () => {
  /* selector */
  const { agent_online, loader_list_online } = useSelector(onlineUsersSelector);
  // const { activeCategoryChat } = useSelector(layoutSetupSelector);
  const [categoryChat, setCategoryChat] = useState('chat-pending');

  return (
    <Fragment>
      <div>
        <div className="px-4 pt-3">
          <ClientHeaderTab attrClass="mb-3" value="Chats" />

          <OnlineUsers
            listAgentOnline={agent_online}
            statusLoader={loader_list_online}
          />
        </div>

        <div className="px-4 pt-2">
          <TabCategoryChat
            activeMenu={categoryChat}
            setCategoryChat={setCategoryChat}
          />
          <hr />
          <ListChat activeMenu={categoryChat} />
        </div>
      </div>
    </Fragment>
  );
};
export default ChatWithClients;
