import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Collapse, CardBody, CardHeader } from 'reactstrap';
const CustomCollapse = (props) => {
  const { isOpen, toggleCollapse } = props;
  return (
    <Fragment>
      <Link to="#" onClick={toggleCollapse} className="text-dark">
        <CardHeader id="profile-user-headingOne">
          <h5 className="font-size-14 m-0">
            {props.iconClass && (
              <i
                className={
                  props.iconClass + ' me-2 align-middle d-inline-block'
                }
              ></i>
            )}
            {props.title}
            <i
              className={
                isOpen
                  ? 'mdi mdi-chevron-up float-end accor-plus-icon'
                  : 'mdi mdi-chevron-right float-end accor-plus-icon'
              }
            ></i>
          </h5>
        </CardHeader>
      </Link>
      <Collapse isOpen={isOpen}>
        <CardBody>{props.children}</CardBody>
      </Collapse>
    </Fragment>
  );
};

export default CustomCollapse;
