import React from 'react';
import { useSelector } from 'react-redux';
import SimpleBar from 'simplebar-react';
import ClientHeaderTab from '../../../../Common/Components/HeaderTab/ClientHeaderTab';
import ClientListChat from '../../../../Common/Components/ListChat/ClientListChat';
import Skeletons from '../../../../Common/Components/Skeletons';
import { detailChatClientSelector } from '../../DetailChat/DetailChatClientSlice';
import { listChatClientSelector } from '../ChatWithClients/ListChat/ListChatClientSlice';

function ChatHistory() {
  /* config and selector */
  const { history, loader_history } = useSelector(listChatClientSelector);
  const { chatId } = useSelector(detailChatClientSelector);
  /* component */
  const { SkeletonListChat } = Skeletons;

  /* conditional func */
  const Items = (params) => {
    const { data, loaderHistory } = params;

    if (loaderHistory) {
      return <SkeletonListChat />;
    }

    if (data.length < 1) {
      return (
        <li id="conversation-chat-empty" className="active">
          <div className="d-flex justify-content-center p-5">
            <h5 className="mb-2 font-size-14">Chat Not Found!</h5>
          </div>
        </li>
      );
    }

    return data.map((val, index) => {
      let isActive = '';
      if (chatId === val.chat_id) {
        isActive = 'active';
      }

      return (
        <ClientListChat
          key={index}
          index={index}
          data={val}
          isActive={`listChatClient ${isActive}`}
          chatIdActive={chatId}
        />
      );
    });
  };

  return (
    <>
      <div>
        <div className="px-4 pt-3">
          <ClientHeaderTab
            attrClass="mb-3"
            value="Chat History"
            isFieldSearch={true}
          />
        </div>
        <hr />
        <SimpleBar
          style={{ maxHeight: '100%' }}
          className="chat-message-list chat-group-list"
        >
          <ul
            className="list-unstyled chat-list chat-user-list px-4"
            id="chat-list"
          >
            <Items loaderHistory={loader_history} data={history} />
          </ul>
        </SimpleBar>
      </div>
    </>
  );
}

export default ChatHistory;
