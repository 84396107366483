import React from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import {
  getChatDetailClient,
  updateOpenChat,
} from '../../../features/Client/DetailChat/DetailChatClientSlice';
import { parseDateNowVWa } from '../../utils/helpers';

/* image default */
import AvatarDefault from '../../../assets/images/users/avatar/avatar-4.png';

function ClientListChat(props) {
  const { data, index, isActive, chatIdActive } = props;
  // const avatar = !Boolean(data.avatar) ? AvatarDefault : data.avatar;
  const avatar = AvatarDefault;

  const dateFormatted = parseDateNowVWa(data.formatted_date);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const eventChatDetail = (event, data) => {
    if (event.altKey) {
      event.preventDefault();
      event.stopPropagation();
    }

    if (data.chat_id !== chatIdActive) {
      dispatch(updateOpenChat(data));

      navigate(`../chat-with-client/${data.chat_id}`);
      dispatch(getChatDetailClient(data.chat_id));
    }
  };

  return (
    <li
      id={`conversation-${data.chat_id}`}
      key={index}
      className={`${isActive}`}
      onClick={(event) => eventChatDetail(event, data)}
    >
      <Link to="#">
        <div className="d-flex">
          <div className="chat-user-img online2 align-self-center me-3 ms-0">
            <img
              src={avatar}
              className="rounded-circle avatar-xs"
              alt="chatvolution-avatar"
            />
            <span className="user-status"></span>
          </div>
          <div className="flex-grow-1 overflow-hidden">
            <h5 className="text-truncate font-size-15 mb-1">
              {data.user_name}
            </h5>
            <p className="chat-user-message text-truncate mb-0">
              {data.message}
            </p>
          </div>
          <div className="font-size-11">{dateFormatted}</div>
        </div>
      </Link>
    </li>
  );
}

export default ClientListChat;
