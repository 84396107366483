import { Fragment } from 'react';

/* router react */
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import socketConnect from '../../Common/WebSocket/SocketConnect';

/* css and framework css */
import Client from '../Client/Client';
import NotFound from '../NotFound/NotFound';
import Validator from '../Validator/Validator';

const Home = () => {
  socketConnect();
  return (
    <Router>
      <Fragment>
        <Routes>
          <Route path="*" element={<NotFound title="404" />} />
          <Route path="/" element={<Validator title="Check Access" />} />
          <Route
            path="/chat-with-client"
            element={<Client title="Chat With Client" />}
          >
            <Route
              path=":chatIdActive"
              element={<Client title="Chat With Client" />}
            />
          </Route>
        </Routes>
      </Fragment>
    </Router>
  );
};

export default Home;
