import { Fragment, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { saveSessions } from '../../app/Auth/AuthSlice';
import '../../assets/scss/loader.scss';
import Service from '../../Common/service';

const { REACT_APP_LIVE_ENDPOINT_V1 } = process.env;

const Validator = (props) => {
  /* configuration from import */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  /* get params from url */
  const token = searchParams.get('token');

  /* update title by name page */
  useEffect(() => {
    document.title = `${props.title} | ${process.env.REACT_APP_NAME}`;
  }, []);

  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'X-Requested-With': 'xmlhttprequest',
      },
    };

    Service.validateSession(config)
      .then(async (response) => {
        const { login_data } = response.data;
        const usersData = {
          agent_id: login_data.id,
          uuid: login_data.uuid,
          name_agent: login_data.name,
          email_agent: login_data.email,
          phone_agent: login_data.phone,
          avatar: login_data.avatar,
          id_department: login_data.id_department,
          department_name: login_data.department_name,
          company_name: login_data.company_name,
          company_id: login_data.id_company,
          id_permission: login_data.permission,
          permission_name: login_data.roles_name,
          token: login_data.token,
          type_user: 'agent',
          status: 'online',
        };

        await Service.authUserToSocket(usersData);

        await dispatch(saveSessions(usersData));

        navigate('../chat-with-client');
      })
      .catch((err) => {
        console.log('error:', err);
        // localStorage.clear();
        // const urlRedirect = `${REACT_APP_LIVE_ENDPOINT_V1}/login`;
        // window.location.replace(urlRedirect);
      });
  }, []);

  return (
    <Fragment>
      <div className="page-loader">
        <div className="loader"></div>
      </div>
    </Fragment>
  );
};
export default Validator;
