import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  fileClientObject: null,
  isSelectedFileClient: false,
  messageClient: null,
  uploadFileResult: {},
};

export const InputChatSlice = createSlice({
  name: 'inputChatSetup',
  initialState,
  reducers: {
    closePreview: (state) => {
      state.uploadFileResult = {};
      state.isSelectedFileClient = false;
    },
    chooseFileFromClient: (state, action) => {
      state.isSelectedFileClient = action.payload;
      state.uploadFileResult = {};
    },
    updateMessage: (state, action) => {
      console.log('actionReduxer:', action);
      state.messageClient = action.payload;
    },
    fullFilledUploadFile: (state, action) => {
      const result = action.payload;
      state.uploadFileResult = result;
    },
  },
});

export const inputChatSelector = (state) => state.inputChatSetup;

export const {
  chooseFileFromClient,
  updateMessage,
  fullFilledUploadFile,
  closePreview,
} = InputChatSlice.actions;

export default InputChatSlice.reducer;
