import { Fragment, useEffect } from 'react';

import { Link } from 'react-router-dom';

//carousel
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import './OnlineUsers.css';

/* reducer and helper */
import { useSelector } from 'react-redux';
import { authSelector } from '../../../../../app/Auth/AuthSlice';
import { validateUsersOnline } from '../../../../../Common/utils/helpers';

/* component */
import { UncontrolledTooltip } from 'reactstrap';
import Skeletons from '../../../../../Common/Components/Skeletons';
import Socket from '../../../../../Common/WebSocket/Socket';
import { updateListAgentOnline } from '../../../../../Common/WebSocket/Clients/ClientUpdateState';

function OnlineUsers(props) {
  /* first configuration */
  const { listAgentOnline, statusLoader } = props;
  const { SkeletonOnlineUserAgent } = Skeletons;
  const responsive = {
    0: { items: 4 },
    1024: { items: 4 },
    animationType: 'fadeout',
    infinite: true,
    isNextSlideDisabled: false,
    isPrevSlideDisabled: false,
  };

  /* selector */
  const { user } = useSelector(authSelector);

  /* render conditional */
  const ListAgent = (params) => {
    const { listAgentOnline, user } = params;

    let data = validateUsersOnline(listAgentOnline, user);

    if (data.length < 1) {
      return (
        <Fragment>
          <div className="pb-0 dot_remove" dir="ltr">
            No Agent Online
          </div>
        </Fragment>
      );
    }

    return data.map((val, index) => {
      return (
        <div className="item" id={`item-` + val.id} key={val.id}>
          <Link to="#" className="user-status-box">
            <div className="avatar-xs mx-auto d-block chat-user-img online">
              <img
                src={val.avatar}
                alt="user-img"
                className="img-fluid rounded-circle"
              />
              <span className="user-status"></span>
            </div>

            <h5 className="font-size-13 text-truncate mt-3 mb-1">
              {val.email}
            </h5>
          </Link>
          <UncontrolledTooltip target={`item-` + val.id} placement="bottom">
            {val.email}
          </UncontrolledTooltip>
        </div>
      );
    });
  };

  if (statusLoader) {
    return <SkeletonOnlineUserAgent />;
  } else {
    return (
      <Fragment>
        <div className="pb-0 dot_remove" dir="ltr">
          <AliceCarousel
            responsive={responsive}
            disableDotsControls={false}
            disableButtonsControls={false}
            keyboardNavigation={true}
            mouseTracking
            renderPrevButton={() => {
              return (
                <div className="alice-carousel__prev-btn">
                  <div className="alice-carousel__prev-btn-wrapper">
                    <p className="alice-carousel__prev-btn-item">
                      <span className="fas fa-caret-left m-1"></span>
                    </p>
                  </div>
                </div>
              );
            }}
            renderNextButton={() => {
              return (
                <div className="alice-carousel__next-btn">
                  <div className="alice-carousel__next-btn-wrapper">
                    <p className="alice-carousel__next-btn-item">
                      <span className="fas fa-caret-right m-1"></span>
                    </p>
                  </div>
                </div>
              );
            }}
          >
            <ListAgent listAgentOnline={listAgentOnline} user={user} />
          </AliceCarousel>
        </div>
      </Fragment>
    );
  }
}

export default OnlineUsers;
