import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledTooltip,
} from 'reactstrap';

/* image default */
import { authSelector } from '../../../app/Auth/AuthSlice';
import AvatarDefault from '../../../assets/images/users/avatar/avatar-4.png';
import ModalMd from '../../../Common/Components/Modal/ModalMd';
import Category from '../../../Common/Components/Tab/TransferChatClient/Category';
import Content from '../../../Common/Components/Tab/TransferChatClient/Content';
import { solveChat } from '../../../Common/WebSocket/Clients/ClientActions';
import { onlineUsersSelector } from '../Tabs/ChatWithClients/OnlineUsers/OnlineUsersSlice';
import { detailChatClientSelector } from './DetailChatClientSlice';
import _ from 'lodash'
function Header(props) {
  const { detailClient, closeChat, uploadFileResult } = props;
  const avatar = AvatarDefault;

  /* state */
  const [dropdownMenuHeader, setDropdownMenuHeader] = useState(false);
  const [transferChatModal, setTransferChatModal] = useState(false);
  const [transferTabActive, setTransferTabActive] = useState('agent-list');
  const { department_online, agent_online } = useSelector(onlineUsersSelector);
  const { user } = useSelector(authSelector);
  const { chatId } = useSelector(detailChatClientSelector);
  /* handler */
  const toggleHandlerHeaderMenu = () =>
    setDropdownMenuHeader(!dropdownMenuHeader);

  const toggleTransferChatModal = () =>
    setTransferChatModal(!transferChatModal);

  const toggleHandlerTabActiveTransfer = (val) => {
    setTransferTabActive(val);
  };

  const ElMenuDekstop = (params) => {
    const { status } = params;
    if (![9, 10, 11].includes(status)) {
      return (
        <>
          <li className="list-inline-item">
            <button
              type="button"
              id="forwardChat"
              className="btn nav-btn"
              onClick={toggleTransferChatModal}
            >
              <i className="ri-chat-forward-fill"></i>
            </button>
            <UncontrolledTooltip target="forwardChat" placement="bottom">
              Transfer Chat
            </UncontrolledTooltip>
          </li>
          <li className="list-inline-item">
            <button type="button" id="modalLabel" className="btn nav-btn">
              <i className="fas fa-user-tag"></i>
            </button>
            <UncontrolledTooltip target="modalLabel" placement="bottom">
              Labels
            </UncontrolledTooltip>
          </li>
          <li className="list-inline-item">
            <button
              type="button"
              id="solveChat"
              className="btn nav-btn"
              onClick={() => solveChat(detailClient.chat_id)}
            >
              <i className="ri-chat-check-fill"></i>
            </button>
            <UncontrolledTooltip target="solveChat" placement="bottom">
              Solve Chat
            </UncontrolledTooltip>
          </li>
        </>
      );
    }
  };

  const ElMenuMobile = (params) => {
    const { status } = params;

    if (![9, 10, 11].includes(status)) {
      return (
        <>
          <DropdownItem>
            Transfer{' '}
            <i className="ri-chat-forward-line float-end text-muted"></i>
          </DropdownItem>
          <DropdownItem>
            Labels <i className="ri-price-tag-3-line float-end text-muted"></i>
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem href="/logout">
            Solve Chat{' '}
            <i className="ri-chat-check-line float-end text-muted"></i>
          </DropdownItem>
        </>
      );
    }
  };

  const ElConditionMenu = (params) => {
    const { uploadFileResult } = params
    console.log("fileResult:", uploadFileResult)
    if (_.isEmpty(uploadFileResult)) {
      return <>
        <Col xs={5} sm={5} className="d-none d-md-block d-lg-block">
          <ul className="list-inline user-chat-nav text-end mb-0">
            <li className="list-inline-item">
              <button
                type="button"
                id="detailClient"
                className="btn nav-btn"
              >
                <i className="ri-user-3-fill"></i>
              </button>
              <UncontrolledTooltip target="detailClient" placement="bottom">
                Detail Client
              </UncontrolledTooltip>
            </li>
            <ElMenuDekstop status={detailClient.status} />
          </ul>
        </Col>
        <Col sm={2} xs={2} className="d-block d-md-none d-lg-none">
          <Dropdown
            isOpen={dropdownMenuHeader}
            toggle={toggleHandlerHeaderMenu}
          >
            <DropdownToggle tag="a" className="ms-3">
              <i className="fas fa-ellipsis-v"></i>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              <DropdownItem>
                Info <i className="ri-user-3-line float-end text-muted"></i>
              </DropdownItem>
              <ElMenuMobile status={detailClient.status} />
            </DropdownMenu>
          </Dropdown>
        </Col>
      </>
    } else {
      console.log("jalan nih sih else")
    }
  }

  return (
    <>
      <div className="p-3 p-lg-4 border-bottom">
        <Row className="align-items-center">
          <Col sm={7} xs={7} className="d-none d-md-block d-lg-block">
            <div className="d-flex align-items-center">
              <div className="me-2 ms-0">
                <button
                  className="btn-user-chat-remove text-muted font-size-16"
                  onClick={(event) => closeChat(event)}
                >
                  <i className="fas fa-arrow-left"></i>
                </button>
              </div>
              <div className="me-3 ms-0">
                <img
                  src={avatar}
                  className="rounded-circle avatar-xs"
                  alt="avatar"
                />
              </div>
              <div className="flex-grow overflow-hidden">
                <h5 className="font-size-16 mb-0 text-truncate">
                  <Link to="#" className="text-reset user-profile-show">
                    {Boolean(detailClient.user_name)
                      ? detailClient.user_name
                      : '-'}
                  </Link>
                </h5>
                <small className="text-truncate">
                  {Boolean(detailClient.user_email)
                    ? detailClient.user_email
                    : '-'}
                </small>
              </div>
            </div>
          </Col>
          <Col sm={10} xs={10} className="d-block d-md-none d-lg-none">
            <div className="d-flex align-items-center">
              <div className="me-2 ms-0">
                <button
                  className="btn-user-chat-remove text-muted font-size-16"
                  onClick={(event) => closeChat(event)}
                >
                  <i className="fas fa-arrow-left"></i>
                </button>
              </div>
              <div className="me-3 ms-0">
                <img
                  src={avatar}
                  className="rounded-circle avatar-xs"
                  alt="avatar"
                />
              </div>
              <div className="flex-grow overflow-hidden">
                <h5 className="font-size-16 mb-0 text-truncate">
                  <Link to="#" className="text-reset user-profile-show">
                    {Boolean(detailClient.user_name)
                      ? detailClient.user_name
                      : '-'}
                  </Link>
                </h5>
                <small className="text-truncate">
                  {Boolean(detailClient.user_email)
                    ? detailClient.user_email
                    : '-'}
                </small>
              </div>
            </div>
          </Col>
          <ElConditionMenu uploadFileResult={uploadFileResult} />
        </Row>
      </div>

      <ModalMd
        isOpen={transferChatModal}
        handlerFunc={toggleTransferChatModal}
        title="Transfer Chat"
        headerModal={true}
        unmountOnClose={true}
        keyboard={true}
        centered={true}
        backdrop="static"
      >
        <Category
          activeTab={transferTabActive}
          handlerClick={toggleHandlerTabActiveTransfer}
        />
        <Content
          sessionUser={user}
          activeTab={transferTabActive}
          dataAgent={agent_online}
          dataDepartment={department_online}
          chatIdActive={chatId}
        />
      </ModalMd>
    </>
  );
}

export default Header;
