/* react */
import { Fragment, useEffect } from 'react';
import { useParams } from 'react-router-dom';

/* redux and reducer */
import { useDispatch } from 'react-redux';

/* css, image */
import '../../assets/scss/themes.scss';

/* component */
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LeftSidebarCLientArea from '../../Common/Components/LeftSidebarClientArea/LeftSidebarClientArea';
import DetailChat from './DetailChat/DetailChat';
import Tabs from './Tabs/Tabs';

/* import socket listner */
import { getDetailClientChat } from './DetailChat/DetailChatClientSlice';

const Client = (props) => {
  const { chatIdActive } = useParams();
  const dispatch = useDispatch();

  /* onload first */
  useEffect(() => {
    if (Boolean(chatIdActive)) {
      dispatch(getDetailClientChat(chatIdActive));
    }
  }, []);

  useEffect(() => {
    document.title = `${props.title} | ${process.env.REACT_APP_NAME}`;
  }, []);

  return (
    <Fragment>
      <ToastContainer limit={5} />
      <div className="layout-wrapper d-lg-flex">
        <LeftSidebarCLientArea />
        <Tabs />
        <DetailChat chatId={chatIdActive} />
      </div>
    </Fragment>
  );
};

export default Client;
