import React from 'react';
import { useSelector } from 'react-redux';
import { layoutSetupSelector } from '../../../app/Layouts/LayoutSlice';
import { getCategoryFile, getExtensionFile } from '../../utils/helpers';
import Avatar from './Avatar';
import Message from './Message';
import MessageFile from './MessageFile';
import NameChat from './NameChat';
import Time from './Time';
function BubbleChatClient(props) {
  /* config */
  const { data } = props;
  const { layoutMode } = useSelector(layoutSetupSelector);

  const Items = (params) => {
    const { data } = params;

    if (!Boolean(data.file_url)) {
      return (
        <>
          <div className="ctext-wrap-content-responsived">
            <Message message={data.message} />
            <Time date={data.formatted_date} />
          </div>
        </>
      );
    }

    const getExtFile = getExtensionFile(data.file_name);
    const categoryFile = getCategoryFile(getExtFile);

    return (
      <>
        <div className="ctext-wrap-content-responsived">
          <MessageFile
            data={data}
            extensionFile={getExtFile}
            categoryFile={categoryFile}
            layoutMode={layoutMode}
          />
          {Boolean(data.message) && <Message message={data.message} />}
          <Time date={data.formatted_date} />
        </div>
      </>
    );
  };

  return (
    <>
      <li className={`${!Boolean(data.is_sender) ? 'left' : 'right'}`}>
        <div className="conversation-list">
          <Avatar avatar={data.avatar} dtClass="chat-avatar" />
          <div className="user-chat-content">
            <NameChat
              name={!Boolean(data.user_name) ? data.agent_name : data.user_name}
              dtClass="conversation-name"
            />
            <div className="ctext-wrap">
              <Items data={data} />
            </div>
          </div>
        </div>
      </li>
    </>
  );
}

export default BubbleChatClient;
