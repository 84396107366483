import { Link } from 'react-router-dom';
import { Col, Placeholder, Row } from 'reactstrap';

function SkeletonListChat() {
  return (
    <>
      <li className="active">
        <Link to="#">
          <Row>
            <Col xs={3}>
              <Placeholder animation="wave" tag="div">
                <Placeholder
                  xs={1}
                  style={{
                    width: '50px',
                    height: '50px',
                    borderRadius: '50%',
                    cursor: 'text',
                  }}
                  color="secondary"
                />
              </Placeholder>
            </Col>
            <Col xs={6}>
              <Placeholder animation="wave" tag="div">
                <Placeholder
                  xs={12}
                  style={{
                    height: '1em',
                    borderRadius: '8px',
                    cursor: 'text',
                  }}
                  color="secondary"
                />
                <Placeholder
                  xs={12}
                  style={{
                    height: '1em',
                    borderRadius: '8px',
                    cursor: 'text',
                  }}
                  color="secondary"
                />
              </Placeholder>
            </Col>
            <Col xs={2}>
              <Placeholder animation="wave" tag="div">
                <Placeholder
                  xs={12}
                  style={{
                    height: '1em',
                    borderRadius: '8px',
                    cursor: 'text',
                  }}
                  color="secondary"
                />
                <Placeholder
                  xs={12}
                  style={{
                    height: '1em',
                    borderRadius: '8px',
                    cursor: 'text',
                  }}
                  color="secondary"
                />
              </Placeholder>
            </Col>
          </Row>
        </Link>
      </li>
      <li>
        <Link to="#">
          <Row>
            <Col xs={3}>
              <Placeholder animation="wave" tag="div">
                <Placeholder
                  xs={1}
                  style={{
                    width: '50px',
                    height: '50px',
                    borderRadius: '50%',
                    cursor: 'text',
                  }}
                  color="secondary"
                />
              </Placeholder>
            </Col>
            <Col xs={6}>
              <Placeholder animation="wave" tag="div">
                <Placeholder
                  xs={12}
                  style={{
                    height: '1em',
                    borderRadius: '8px',
                    cursor: 'text',
                  }}
                  color="secondary"
                />
                <Placeholder
                  xs={12}
                  style={{
                    height: '1em',
                    borderRadius: '8px',
                    cursor: 'text',
                  }}
                  color="secondary"
                />
              </Placeholder>
            </Col>
            <Col xs={2}>
              <Placeholder animation="wave" tag="div">
                <Placeholder
                  xs={12}
                  style={{
                    height: '1em',
                    borderRadius: '8px',
                    cursor: 'text',
                  }}
                  color="secondary"
                />
                <Placeholder
                  xs={12}
                  style={{
                    height: '1em',
                    borderRadius: '8px',
                    cursor: 'text',
                  }}
                  color="secondary"
                />
              </Placeholder>
            </Col>
          </Row>
        </Link>
      </li>
      <li>
        <Link to="#">
          <Row>
            <Col xs={3}>
              <Placeholder animation="wave" tag="div">
                <Placeholder
                  xs={1}
                  style={{
                    width: '50px',
                    height: '50px',
                    borderRadius: '50%',
                    cursor: 'text',
                  }}
                  color="secondary"
                />
              </Placeholder>
            </Col>
            <Col xs={6}>
              <Placeholder animation="wave" tag="div">
                <Placeholder
                  xs={12}
                  style={{
                    height: '1em',
                    borderRadius: '8px',
                    cursor: 'text',
                  }}
                  color="secondary"
                />
                <Placeholder
                  xs={12}
                  style={{
                    height: '1em',
                    borderRadius: '8px',
                    cursor: 'text',
                  }}
                  color="secondary"
                />
              </Placeholder>
            </Col>
            <Col xs={2}>
              <Placeholder animation="wave" tag="div">
                <Placeholder
                  xs={12}
                  style={{
                    height: '1em',
                    borderRadius: '8px',
                    cursor: 'text',
                  }}
                  color="secondary"
                />
                <Placeholder
                  xs={12}
                  style={{
                    height: '1em',
                    borderRadius: '8px',
                    cursor: 'text',
                  }}
                  color="secondary"
                />
              </Placeholder>
            </Col>
          </Row>
        </Link>
      </li>
      <li>
        <Link to="#">
          <Row>
            <Col xs={3}>
              <Placeholder animation="wave" tag="div">
                <Placeholder
                  xs={1}
                  style={{
                    width: '50px',
                    height: '50px',
                    borderRadius: '50%',
                    cursor: 'text',
                  }}
                  color="secondary"
                />
              </Placeholder>
            </Col>
            <Col xs={6}>
              <Placeholder animation="wave" tag="div">
                <Placeholder
                  xs={12}
                  style={{
                    height: '1em',
                    borderRadius: '8px',
                    cursor: 'text',
                  }}
                  color="secondary"
                />
                <Placeholder
                  xs={12}
                  style={{
                    height: '1em',
                    borderRadius: '8px',
                    cursor: 'text',
                  }}
                  color="secondary"
                />
              </Placeholder>
            </Col>
            <Col xs={2}>
              <Placeholder animation="wave" tag="div">
                <Placeholder
                  xs={12}
                  style={{
                    height: '1em',
                    borderRadius: '8px',
                    cursor: 'text',
                  }}
                  color="secondary"
                />
                <Placeholder
                  xs={12}
                  style={{
                    height: '1em',
                    borderRadius: '8px',
                    cursor: 'text',
                  }}
                  color="secondary"
                />
              </Placeholder>
            </Col>
          </Row>
        </Link>
      </li>
      <li>
        <Link to="#">
          <Row>
            <Col xs={3}>
              <Placeholder animation="wave" tag="div">
                <Placeholder
                  xs={1}
                  style={{
                    width: '50px',
                    height: '50px',
                    borderRadius: '50%',
                    cursor: 'text',
                  }}
                  color="secondary"
                />
              </Placeholder>
            </Col>
            <Col xs={6}>
              <Placeholder animation="wave" tag="div">
                <Placeholder
                  xs={12}
                  style={{
                    height: '1em',
                    borderRadius: '8px',
                    cursor: 'text',
                  }}
                  color="secondary"
                />
                <Placeholder
                  xs={12}
                  style={{
                    height: '1em',
                    borderRadius: '8px',
                    cursor: 'text',
                  }}
                  color="secondary"
                />
              </Placeholder>
            </Col>
            <Col xs={2}>
              <Placeholder animation="wave" tag="div">
                <Placeholder
                  xs={12}
                  style={{
                    height: '1em',
                    borderRadius: '8px',
                    cursor: 'text',
                  }}
                  color="secondary"
                />
                <Placeholder
                  xs={12}
                  style={{
                    height: '1em',
                    borderRadius: '8px',
                    cursor: 'text',
                  }}
                  color="secondary"
                />
              </Placeholder>
            </Col>
          </Row>
        </Link>
      </li>
      <li>
        <Link to="#">
          <Row>
            <Col xs={3}>
              <Placeholder animation="wave" tag="div">
                <Placeholder
                  xs={1}
                  style={{
                    width: '50px',
                    height: '50px',
                    borderRadius: '50%',
                    cursor: 'text',
                  }}
                  color="secondary"
                />
              </Placeholder>
            </Col>
            <Col xs={6}>
              <Placeholder animation="wave" tag="div">
                <Placeholder
                  xs={12}
                  style={{
                    height: '1em',
                    borderRadius: '8px',
                    cursor: 'text',
                  }}
                  color="secondary"
                />
                <Placeholder
                  xs={12}
                  style={{
                    height: '1em',
                    borderRadius: '8px',
                    cursor: 'text',
                  }}
                  color="secondary"
                />
              </Placeholder>
            </Col>
            <Col xs={2}>
              <Placeholder animation="wave" tag="div">
                <Placeholder
                  xs={12}
                  style={{
                    height: '1em',
                    borderRadius: '8px',
                    cursor: 'text',
                  }}
                  color="secondary"
                />
                <Placeholder
                  xs={12}
                  style={{
                    height: '1em',
                    borderRadius: '8px',
                    cursor: 'text',
                  }}
                  color="secondary"
                />
              </Placeholder>
            </Col>
          </Row>
        </Link>
      </li>
    </>
  );
}

export default SkeletonListChat;
