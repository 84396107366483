import { store } from '../../../app/store';
import {
  addMessage,
  closeChat,
} from '../../../features/Client/DetailChat/DetailChatClientSlice';
import {
  updateOngoingList,
  updatePendingList,
  updateResolveList,
  updateTransferList,
} from '../../../features/Client/Tabs/ChatWithClients/ListChat/ListChatClientSlice';
import {
  updateListAgents,
  updateListDepartments,
} from '../../../features/Client/Tabs/ChatWithClients/OnlineUsers/OnlineUsersSlice';
import { notify } from '../../utils/helpers';

/* update agent online from listener socket */
export const updateListAgentOnline = (data) => {
  store.dispatch(updateListAgents(data));
};

/* update department agent online from listener socket */
export const updateListDepartmentOnline = (data) => {
  store.dispatch(updateListDepartments(data));
};

/* update list pendig */
export const updateDataListPending = (data) => {
  store.dispatch(updatePendingList(data));
};

/* update list ongoing */
export const updateDataListOngoing = (data) => {
  store.dispatch(updateOngoingList(data));
};

/* update list transfer */
export const updateListTransfer = (data) => {
  store.dispatch(updateTransferList(data));
};

/* update list resolve or history chat */
export const updateDataListResolve = (data) => {
  store.dispatch(updateResolveList(data));
};

/* update list bubble chat */
export const updateListBubbleChat = (data) => {
  console.log('dataMessage:', data);
  store.dispatch(addMessage(data));
};

/* alert transfer chat */
export const alertTransferChat = (response) => {
  if (response.success === true) {
    return notify('success', 3000, 'Transfer Chat Success!');
  } else {
    return notify('error', 4000, response.message);
  }
};

/* update State before chat solved */
export const beforeSolveChat = (response) => {
  if (response.success) {
    store.dispatch(closeChat());
    return notify('success', 3000, 'Resolve Chat Success!');
  } else {
    return notify('error', 4000, response.message);
  }
};
