import Service from '../../../Common/service';

/* get detail chat */
export const getDetailChat = async (chatId) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const path = `chat-details/${chatId}`;

  const response = await Service.getDetailChatClient(path, config);
  return response;
};

/* get detail client chat*/
export const getClientDetailChat = async (chatId) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const path = `client-details/${chatId}`;

  const response = await Service.getDetailClientChat(path, config);
  return response;
};
