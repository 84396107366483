import React, { Fragment, useEffect } from 'react';

/* reduxer */
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { layoutSetupSelector } from '../../../app/Layouts/LayoutSlice';
import {
  detailChatClientSelector,
  getChatDetailClient,
  updateStatusNavigate
} from './DetailChatClientSlice';
import Error from './Error';

/* component */
import NotOpened from './NotOpened';
import Opened from './Opened';

function DetailChat(props) {
  /* config */
  const { chatId } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  /* selector from reducer*/
  const { layoutMode } = useSelector(layoutSetupSelector);
  const { isOpen, listBubbleChat, detailClient, listLabel, runNavigateClose } =
    useSelector(detailChatClientSelector);

  useEffect(() => {
    if (runNavigateClose) {
      navigate('/chat-with-client');
      dispatch(updateStatusNavigate());
    }
  }, [runNavigateClose]);

  useEffect(() => {
    if (Boolean(chatId)) {
      setTimeout(() => {
        dispatch(getChatDetailClient(chatId));
      }, 1500);
    }
  }, []);

  const Features = (params) => {
    const { isOpen } = params;
    if (isOpen === 'not_open') {
      return <NotOpened layoutMode={layoutMode} />;
    } else if (isOpen === 'error') {
      return <Error layoutMode={layoutMode} />;
    } else {
      return (
        <Opened
          statusDetail={isOpen}
          detailClient={detailClient}
          listBubbleChat={listBubbleChat}
        />
      );
    }
  };

  return (
    <Fragment>
      <div
        className={`w-100 ${isOpen === 'open' ? 'user-chat' : 'non-user-chat'
          } user-chat-show`}
      >
        {/* {features(isOpen, listBubbleChat)} */}
        <Features isOpen={isOpen} />
      </div>
    </Fragment>
  );
}

export default DetailChat;
