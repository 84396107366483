import React from 'react';
import { Link } from 'react-router-dom';
import { Card, UncontrolledTooltip } from 'reactstrap';
import {
  createDate,
  formatBytes,
  limitText,
  randomString,
} from '../../utils/helpers';
import FancyApp from '../FancyApp/FancyApp';

function MessageFile(props) {
  const { data, extensionFile, layoutMode, categoryFile } = props;
  const configFancyApps = {
    Toolbar: {
      display: ['download', 'close'],
    },
  };

  const Items = (params) => {
    const { data, extensionFile, categoryFile } = params;
    if (categoryFile.name === 'image') {
      return (
        <>
          <FancyApp options={configFancyApps}>
            <a
              data-fancybox={`image-chat-${randomString(15)}`}
              href={data.file_url}
              data-caption={
                !Boolean(data.message) ? '' : limitText(data.message, 0, 100)
              }
              className={`img-parent popup-img d-inline-block m-1 text-center  ${
                layoutMode === 'dark' ? 'bg-white' : 'bg-color-gray-700'
              }`}
              title="show image"
            >
              <img src={data.file_url} alt="chat" className="mx-auto rounded" />
            </a>
          </FancyApp>
          <div>
            <a
              href={data.file_url}
              target="_blank"
              download
              className="text-muted text-end"
              title="download"
            >
              <i className="ri-download-2-line"></i>
            </a>
          </div>
        </>
      );
    }

    let iconFile = '';
    if (categoryFile.name === 'video') {
      iconFile = 'fas fa-file-video';
    } else if (categoryFile.name === 'archived') {
      iconFile = 'fa-solid fa-file-zipper';
    } else if (categoryFile.name === 'code') {
      iconFile = 'fas fa-file-code';
    } else {
      switch (extensionFile) {
        case 'crt':
          iconFile = 'fas fa-file-certificate';
          break;
        case 'csr':
          iconFile = 'fas fa-file-certificate';
          break;
        case 'pdf':
          iconFile = 'fas fa-file-pdf';
          break;
        case 'doc':
          iconFile = 'fas fa-file-word';
          break;
        case 'docx':
          iconFile = 'fas fa-file-word';
          break;
        case 'xls':
          iconFile = 'fas fa-file-excel';
          break;
        case 'xlsx':
          iconFile = 'fas fa-file-excel';
          break;
        case 'csv':
          iconFile = 'fas fa-file-csv';
          break;
        default:
          iconFile = 'fas fa-file';
          break;
      }
    }

    const specialId = `fileName-${randomString(10)}-${data.file_id}`;

    return (
      <>
        <Card className="p-2 mb-2 file-text-responsived">
          <div className="d-flex align-items-center">
            <div className="avatar-sm me-3 ms-0">
              <div className="avatar-title bg-color-gray-700 text-white rounded">
                <i className={iconFile}></i>
              </div>
            </div>
            <div className="flex-grow-1">
              <div className="text-start">
                <h5
                  className="font-size-14 mb-1 d-inline-block  text-truncate"
                  style={{ cursor: 'help' }}
                  id={specialId}
                >
                  {data.file_name}
                </h5>
                <UncontrolledTooltip target={specialId} placement="top">
                  {data.file_name}
                </UncontrolledTooltip>
                <p className="text-muted font-size-13 mb-0">
                  {formatBytes(data.file_type)}
                </p>
              </div>
            </div>

            <div className="ms-4">
              <ul className="list-inline mb-0 font-size-20">
                <li className="list-inline-item">
                  <a
                    href={data.file_url}
                    target="_blank"
                    download
                    className="text-muted"
                  >
                    <i className="ri-download-2-line"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </Card>
      </>
    );
  };

  return (
    <div className="message-img mb-0">
      <div>
        <Items
          data={data}
          extensionFile={extensionFile}
          categoryFile={categoryFile}
        />
      </div>
    </div>
  );
}

export default MessageFile;
