import React from 'react'
import { Col, Form, Row } from 'reactstrap'

function SolveInput() {
    return (
        <div className="p-3 p-lg-4 border-top mb-0">
            <Form>
                <Row className="g-0">
                    <Col>
                        <div className='text-center'>
                            <h5 className='fw-bold'>
                                Chat Solved
                            </h5>
                        </div>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default SolveInput
