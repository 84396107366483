import { store } from '../../../app/store';
import { closeChat } from '../../../features/Client/DetailChat/DetailChatClientSlice';
import { updateMessage } from '../../Components/InputChat/InputChatSlice';
import Socket from '../Socket';

export const refreshData = () => {
  Socket.emit('reload');
};

export const joinRoom = (chatId) => {
  Socket.emit('room.join', chatId);
};

export const sendMessage = (chatId, message, file = {}) => {
  console.log('message:', message);
  const dataSenderDefault = { chatId, message };
  const resultDataSender = Object.assign(dataSenderDefault, file);

  Socket.emit('message', resultDataSender);
  store.dispatch(updateMessage(null));
};

export const solveChat = (chatId) => {
  Socket.emit('chat.end', { chatId });
};

export const handlerTransferChat = (chatId, id, type) => {
  const data = {
    chatId,
  };

  if (type === 'user') {
    data.toAgent = id;
  } else {
    data.toDepartment = id;
  }

  Socket.emit('chat.transfer', data);
  store.dispatch(closeChat());
};
