import Service from '../../service';

export const uploadFile = async (token, file, getExtensionFile) => {
  const formData = new FormData();
  formData.append('file', file);
  const path = 'api/chat/agent/upload-file';

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      'X-Requested-With': 'xmlhttprequest',
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await Service.uploadFileChat(path, formData, config);
  return response;
};
