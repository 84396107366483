import {
  createSlice
} from '@reduxjs/toolkit';

/* layout mode default */
const layoutMode = !Boolean(localStorage.getItem('layoutMode')) ?
  'dark' :
  localStorage.getItem('layoutMode');

const initialState = {
  layoutMode: layoutMode,
  activeTab: 'Chat-w-clients',
  // activeTab: 'Chat-w-history',
  activeCategoryChat: 'chat-pending',
};

export const LayoutClientAreaSlice = createSlice({
  name: 'layoutSetup',
  initialState,
  reducers: {
    changeLayoutMode: (state, action) => {
      state.layoutMode = action.payload;
      localStorage.setItem('layoutMode', action.payload);
      document.body.setAttribute('data-layout-mode', action.payload);
    },
    changeTabMenu: (state, action) => {
      state.activeTab = action.payload;
    },
    changeCategoryChat: (state, action) => {
      state.activeCategoryChat = action.payload;
    },
  },
});

/* export state */
export const layoutSetupSelector = (state) => state.layoutSetup;

/* export command function from reducer*/
export const {
  changeLayoutMode,
  changeTabMenu,
  changeCategoryChat
} =
LayoutClientAreaSlice.actions;

/* exporting all reduxer layout slice*/
export default LayoutClientAreaSlice.reducer;