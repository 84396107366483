import {
  alertTransferChat,
  beforeSolveChat,
  updateDataListOngoing,
  updateDataListPending,
  updateDataListResolve,
  updateListAgentOnline,
  updateListBubbleChat,
  updateListDepartmentOnline,
  updateListTransfer,
} from './ClientUpdateState';

const ClientListens = (Socket) => {
  Socket.on('users.online', (data) => {
    updateListAgentOnline(data);
  });

  Socket.on('departments.online', (data) => {
    updateListDepartmentOnline(data);
  });

  Socket.on('chat.pending', (data) => {
    updateDataListPending(data);
  });

  Socket.on('chat.ongoing', (data) => {
    updateDataListOngoing(data);
  });

  Socket.on('chat.pendingtransfer', (data) => {
    updateListTransfer(data);
  });

  Socket.on('chat.transferresult', (response) => {
    alertTransferChat(response);
  });

  Socket.on('chat.resolve', (data) => {
    updateDataListResolve(data);
  });

  Socket.on('room.joinresult', (response) => {
    console.warn('statusJoined:', response.status);
  });

  Socket.on('message', (data) => {
    updateListBubbleChat(data);
  });

  Socket.on('chat.endresult', (response) => {
    beforeSolveChat(response);
  });
};

export default ClientListens;
