import React from 'react';
import { parseDateNowVWa } from '../../utils/helpers';

function Time(props) {
  const { date } = props;

  const dateFormatted = parseDateNowVWa(date);

  return (
    <p className="chat-time mb-0">
      {/* <i className="ri-time-line align-middle"></i>{' '} */}
      <span className="align-middle">{dateFormatted}</span>
    </p>
  );
}

export default Time;
