import React, { Fragment } from 'react';
import classnames from 'classnames';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { changeCategoryChat } from '../../../../../app/Layouts/LayoutSlice';
import { useDispatch } from 'react-redux';

function TabCategoryChat(props) {
  /* configuration */
  const { activeMenu, setCategoryChat } = props;
  const dispatch = useDispatch();

  /* handler */
  const changeActiveCategoryChat = (val) => {
    // dispatch(changeCategoryChat(val));
    setCategoryChat(val);
  };

  return (
    <Fragment>
      <Nav pills className="mb-3">
        <NavItem id="chat-pending">
          <NavLink
            className={classnames({
              active: activeMenu === 'chat-pending',
            })}
            onClick={() => {
              changeActiveCategoryChat('chat-pending');
            }}
          >
            Pending
          </NavLink>
        </NavItem>
        <NavItem id="chat-transfer">
          <NavLink
            className={classnames({
              active: activeMenu === 'chat-transfer',
            })}
            onClick={() => {
              changeActiveCategoryChat('chat-transfer');
            }}
          >
            Transfer
          </NavLink>
        </NavItem>
        <NavItem id="chat-ongoing">
          <NavLink
            className={classnames({
              active: activeMenu === 'chat-ongoing',
            })}
            onClick={() => {
              changeActiveCategoryChat('chat-ongoing');
            }}
          >
            On Going
          </NavLink>
        </NavItem>
      </Nav>
    </Fragment>
  );
}

export default TabCategoryChat;
