import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  pending: [],
  transfer: [],
  ongoing: [],
  history: [],
  loader_list_chat: false,
  loader_ongoing: false,
  loader_transfer: false,
  loader_history: false,
};

export const ListChatClientSlice = createSlice({
  name: 'listChatClientSetup',
  initialState,
  reducers: {
    changeLoaderStatusAllChat: (state) => {
      state.loader_pending = true;
      state.loader_ongoing = true;
      state.loader_transfer = true;
      state.loader_history = true;
      state.pending = [];
      state.transfer = [];
      state.ongoing = [];
      state.history = [];
      state.chat_active = null;
    },
    updatePendingList: (state, action) => {
      state.pending = action.payload;
      state.loader_pending = false;
    },
    updateOngoingList: (state, action) => {
      state.ongoing = action.payload;
      state.loader_ongoing = false;
    },
    updateTransferList: (state, action) => {
      state.transfer = action.payload;
      state.loader_transfer = false;
    },
    updateResolveList: (state, action) => {
      state.history = action.payload;
      state.loader_history = false;
    },
    closeChat: (state, action) => {
      return state;
    },
  },
});

/* export state */
export const listChatClientSelector = (state) => state.listChatClientSetup;

/* export command function from reducer */
export const {
  updatePendingList,
  updateOngoingList,
  updateTransferList,
  closeChat,
  changeLoaderStatusAllChat,
  updateResolveList,
} = ListChatClientSlice.actions;

/* export all reduxer ListChatClientSlice */
export default ListChatClientSlice.reducer;
