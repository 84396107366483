import React from 'react';
import { Input, InputGroup } from 'reactstrap';

function ClientHeaderTab(props) {
  const { attrClass, value, isFieldSearch } = props;

  const renderFieldSearch = (status = false) => {
    if (status) {
      return (
        <>
          <div className="search-box chat-search-box">
            <InputGroup size="md" className="mb-3 rounded-lg">
              <span
                className="input-group-text text-muted bg-light pe-1"
                id="basic-addon1"
              >
                <i className="ri-search-line search-icon font-size-18"></i>
              </span>
              <Input
                type="text"
                className="form-control bg-light"
                placeholder="Search messages or users"
              />
            </InputGroup>
          </div>
        </>
      );
    }
  };

  return (
    <>
      <h4 className={attrClass}>{value}</h4>
      {renderFieldSearch(isFieldSearch)}
    </>
  );
}

export default ClientHeaderTab;
